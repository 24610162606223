






















import {Vue, Component, Model, Prop, Watch} from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";
import CompanyService from "@/services/CompanyService";

@Component({})
export default class SimpleCompanySearch extends Vue {

  @Model('change', {type: CompanyDTO, default: null})
  private selectedCompany!: CompanyDTO | null;

  @Prop({default: "companies"})
  private name!: string

  @Prop({default: false})
  private required!: boolean;

  private filter = new CompanyFilterDTO({pageSize: 8});

  private companies: Array<CompanyDTO> = [];

  private showList = false;

  mounted(){
    if(this.selectedCompany){
      this.filter.searchQuery = this.selectedCompany.name + ' ' + this.selectedCompany.ein + ' ' + this.selectedCompany.email;
    }
  }

  @debounce(200)
  search(){
    CompanyService.getByFilter(this.filter).then(
        ok => {
          this.companies = ok.data.data;
          this.showList = true;
        },
        err => {
          console.log(JSON.stringify(err))
        }
    )
  }

  selectCompany(c: CompanyDTO){
    this.showList = false;
    this.filter.searchQuery = c.name + ' ' + c.ein + ' ' + c.email;
    this.$emit('change', c);
  }

  @Watch("selectedCompany")
  watchCompany(_new: CompanyDTO | null, old: CompanyDTO | null){
    if(_new == null){
      this.filter.searchQuery = null
    }
  }

}
